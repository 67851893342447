import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "ok-text": "Save", "title": "Edit Exchange Rate Overrides", "after-close": _vm.afterModalClose, "width": 520 }, on: { "ok": _vm.submit }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.onCancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "loading": _vm.isLoading, "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.submit);
      } } }, [_vm._v("Save")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { key: "CountryId", attrs: { "label": "Country", "reference": "web-analytics.common.ppv-countries", "placeholder": "Select Country", "source": "countryId", "source-label": "countryCode", "value": _vm.country, "rules": "required", "disabled": true, "span": 12, "form-item": "", "on-select-change": _vm.onCountryChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("text-input", { attrs: { "label": "Item Code", "placeholder": "Type Item Code", "value": _vm.entity.itemCode, "rules": "required", "span": 12, "form-item": "" }, on: { "change": _vm.onItemCodeChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("text-input", { attrs: { "label": "Currency", "placeholder": "Type Currency", "value": _vm.entity.currency, "rules": "required", "span": 12, "form-item": "" }, on: { "change": _vm.onCurrencyChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("date-picker", { attrs: { "year-only": true, "label": "Fin Year", "placeholder": "Select Year", "format-date": "YYYY", "rules": "required", "span": 12, "value": "" + _vm.entity.finYear, "form-item": "" }, on: { "change": _vm.onFinYearChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("text-input", { attrs: { "label": "Exchange Rates", "placeholder": "Type Exchange Rate", "value": _vm.entity.exchangeRate, "rules": _vm.regex1digits7frac, "span": 12, "form-item": "" }, on: { "change": _vm.onExchangeRateChange } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "EditExchangeRateOverrides",
  inject: ["crud", "redirectRoute"],
  mixins: [MyUtil],
  data() {
    return {
      apiUrl: apiUrl$1,
      visible: false,
      isLoading: false,
      exchangeRate: "",
      finYear: "",
      currency: "",
      itemCode: ""
    };
  },
  computed: {
    entity() {
      return this.crud.getEntity();
    },
    country() {
      var country = this.crud.getQueryString("CountryID").value;
      return country;
    }
  },
  mounted() {
    this.visible = true;
  },
  methods: {
    async submit() {
      this.isLoading = true;
      await this.axios.put(`${this.apiUrl}/web-analytics/exchange-rate-overrides`, {
        countryId: this.country,
        currency: this.currency ? this.currency : this.entity.currency,
        finYear: this.finYear ? this.finYear : this.entity.finYear,
        itemCode: this.itemCode ? this.itemCode : this.entity.itemCode,
        exchangeRate: this.exchangeRate ? this.exchangeRate : this.entity.exchangeRate
      }).then(() => {
        this.isLoading = false;
        this.visible = false;
        this.crud.fetchList();
        this.$notification.success({
          message: "Item Updated"
        });
      }).catch(() => {
        this.isLoading = false;
      });
    },
    afterModalClose() {
      this.$router.push(this.redirectRoute);
    },
    onCancel() {
      this.visible = false;
    },
    onCountryChange(value) {
      this.crud.setQueryString("CountryID", value);
    },
    onExchangeRateChange(value) {
      this.exchangeRate = value;
    },
    onFinYearChange(value) {
      this.finYear = value;
    },
    onCurrencyChange(value) {
      this.currency = value;
    },
    onItemCodeChange(value) {
      this.itemCode = value;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditExchangeRateOverrides = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "web-analytics.common.ppv-countries" } }), _c("resource", { attrs: { "name": "web-analytics.exchange-rate-overrides", "resource-id-name": "costID", "api-url": _vm.apiUrl, "redirect-route": "/web-analytics/exchange-rate-overrides" } }, [_c("edit-exchange-rate-overrides")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditExchangeRateOverrides
  },
  data() {
    return {
      EditExchangeRateOverrides,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
